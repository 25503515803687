const paths = {
  myCards: '',
  profile: 'profile',
  transferAndPay: '/transfer-and-pay',
  support: '/support',
  blockCard: 'block',
  setPin: 'set-pin',
  changePin: 'change-pin',
  replaceCard: 'replace',
  dispute: 'dispute',
  closeCard: 'close',
  transferMoney: 'transfer-money',
  cardControl: 'card-control',
  cardTransactions: 'card-transactions',
  home: 'home',
  wallets: 'wallets',
  myCard: '/:id',
};

export default paths;
